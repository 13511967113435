import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Post from '../Post';

import './index.scss';

export default function Posts({ posts }) {
  const [postsLoaded, setPostsLoaded] = useState(6);

  const displayedPosts = posts.slice(0, postsLoaded).map(({ node }) => (
    <Post
      key={node.id}
      node={node}
    />
  ));

  const loadMore = postsLoaded < posts.length ? (
    <div className="Posts__pagination">
      <Button onClick={() => setPostsLoaded(postsLoaded + 6)}>
        View more
      </Button>
    </div>
  ) : null;

  return (
    <section className="Posts section-padding">
      <div className="grid">
        <div className="Posts__container">
          {displayedPosts}
        </div>
        {loadMore}
      </div>
    </section>
  )
}

Posts.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
