import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import useAnimateOnScroll from '../../utils/useAnimateOnScroll';

import './index.scss';

export default function Post({ node }) {
  useAnimateOnScroll();

  const title = node.frontmatter.title;

  return (
    <article
      className="Post"
      itemProp="blogPost"
      itemScope
      itemType="http://schema.org/BlogPosting"
      data-aos="slide-up"
    >
      <Link className="Post__link" to={node.fields.slug}>
        <figure className="Post__img">
          <img
            src={node.frontmatter.image}
            alt={title}
            itemProp="image"
          />
        </figure>
        <h3 itemProp="headline">{title}</h3>
        <time
          className="Post__time"
          dateTime=""
          itemProp="datePublished"
        >
          {node.frontmatter.date}
        </time>
        <span className="Post__view">View</span>
      </Link>
    </article>
  );
}

Post.propTypes = {
  node: PropTypes.object.isRequired,
};
