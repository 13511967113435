import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Intro from '../components/Intro';
import Posts from '../components/Posts';

export default function BlogPage() {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: {regex: "/(blog)/"} },
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "MMMM YYYY")
              image
            }
            html
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const posts = allMarkdownRemark.edges;

  return (
    <Fragment>
      <SEO title="Blog" />
      <Intro
        className="Intro--blog"
        title="Read All About It"
        subtitle="Get your paws on product reviews, product lists, gift guides and more."
        showButton={false}
      />
      <Posts posts={posts} />
    </Fragment>
  );
}
